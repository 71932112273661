$(document).foundation();

$(document).ready(function() {
    var lightbox = $('.lightboxGrid a').simpleLightbox({
      showCounter: false,
      navText: ['<img src="../assets/img/icons/10.svg" class="sliderArrow"/>', '<img src="../assets/img/icons/11.svg" class="sliderArrow"/>']
    });

    $('.formError').hide();

    $( "#contactButton" ).click(function( event ) {
      $('.formError').hide();
      event.preventDefault();
      var name = $('#contactName').val();
      var email = $('#contactEmail').val();
      var message = $('#contactMessage').val();
      if (name == '' || message == '') {
        $('#formFillOut').show();
        return;
      }

      if (!isValidEmailAddress(email)){
        $('#invalidEmail').show();
        return;
      }

      $('#formSuccess').show();
      $('#contactName').val('');
      $('#contactEmail').val('');
      $('#contactMessage').val('');

      var data = {
        'name': name,
        'email': email,
        'message': message
      }

      $.ajax({
        type: "POST",
        url: 'https://georgereasoncontact.herokuapp.com/quickEmail',
        data: data,
        success: success,
        dataType: "json"
      });

      function success() {
        console.log("sent");
        $('#formContainer').hide();
        $('#formSuccess').show();
      }
    });

    function isValidEmailAddress(emailAddress) {
      var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
      return pattern.test(emailAddress);
    };
})
